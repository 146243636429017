import React, { ReactElement, useState, useEffect } from 'react';
import './project-info.scss';
import Markdown from 'markdown-to-jsx';

interface ChipProps {
	chipContent: string;
	bgColor?: string;
	textColor?: string;
}

interface Props {
	projectKey: string;
}
interface IProjectData {
	markdown: string;
	stack: any[];
	demoUrl: string;
}

export default function ProjectInfo({
	projectKey
}: Props): ReactElement {
	const [projectMd, setProjectMd] = useState<string>('');
	useEffect(() => {
		const setProject = async () => {
			setProjectMd(await getMarkdownFile(projectKey));
		};
		setProject();
	}, [projectKey]);
	return (
		<Markdown>{projectMd}</Markdown>
	);
}

// function Chip(props: ChipProps) {
// 	const { textColor, bgColor, chipContent } = props;
// 	return (
// 		<span
// 			className='stack-item'>
// 			{chipContent}
// 		</span>
// 	);
// }

async function getMarkdownFile(filePath: string) {
	return await (await fetch(`/assets/projects/${filePath}/${filePath}.md`)).text();
}